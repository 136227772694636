import './content4.scss';
import contentImg3 from '../../../img/page-contant/content4/ct1111.jpg';
import contentImg3mob from '../../../img/page-contant/content4/ct1111-mob.jpg'

const Content4 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'content4');
 
	contentElement.innerHTML = `
	<div class='content4__container'>
	<img class="content4__img-mob" width="432" height="210" src="${contentImg3mob}">
	<img class="content4__img" width="1180" height="310" src="${contentImg3}">
	
	<div class="content4__body">
	<h2 class="content4__title">

	Почему так важно знать все об инвестициях
	</h2>
		<p class="content4__text">
		Зарабатывать на инвестициях можно разными способами. Это может быть трейдинг или получение пассивного дохода. В первом случае человек регистрируется на бирже или сайте брокерской компании, покупает активы по низкой цене, а потом продает их по более высокой стоимости. Второй способ заключается во вложении денег в определенные активы и получение дивидендов. Это долгосрочные инвестиции. Инвестор, не обладая знаниями в этой сфере, не сможет получить прибыль.
		
		</p>
	<p class="content4__text">
	Четкое определение целей инвестирования поможет разработать подходящую инвестиционную стратегию. Знание того, как это сделать даст возможность обрести финансовую независимость при помощи дополнительных источников дохода, защититься от инфляции, не только не потеряв, но и приумножив сбережения даже в условиях экономического кризиса. Обучение помогает понять важные инвестиционные принципы, что позволит лучше управлять рисками и принимать обоснованные решения, и снизит вероятность финансовых потерь, при инвестировании денег, получить финансовое образование, которое поможет более эффективно планировать свой бюджет, и избегать ошибок, которые могут привести к потере инвестиционного капитала.
	
	</p>

	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Content4;