
import { Header1 } from './components/PageHeaders';
import { Footer1 } from './components/PageFooter';
import { Hero3} from './components/PageHeros';

import {Content1,Content2,Content3,Content4} from './components/PageContent';
import { Contents1,Contents2,Contents3,Contents4} from './components/PageContent2';
import { Contentt1,Contentt2,Contentt3,Contentt4} from './components/PageContent3';
import { Contentf1, Contentf2, Contentf3,Contentf4} from './components/PageContent4';
import './index.html';
import './style.scss';


import { setupMenu } from './functions/menu';
import { setupPopup } from './functions/popup';


document.addEventListener('DOMContentLoaded', () => {



	const app = document.getElementById('app');
	const header1Copmonent = Header1();
	const footer1Component = Footer1();
	const hero1Component = Hero3(); 
	const contentComponent = Content4();

	const content2Component1 =Contents1();
	
	const content3Component1= Contentt2();

	const content4Component1 = Contentf3();

	const componentsArray = [header1Copmonent, hero1Component,
		contentComponent,
		content2Component1,
		content3Component1, content4Component1,footer1Component
	];
	

	const addChild = (array)=>{
		array.forEach(element => {
			if (element){
				app.appendChild(element);
			}
		});
	}
	addChild(componentsArray);
	
	
    setupMenu();
    setupPopup(app);
	


 });